import swal from "sweetalert2";
import store from "../store";
import { AUTH_PROFILE_LOCK } from "../store/actionType";

const ERROR_AUTH_PROFILE_LOCKED = 1007;
const ERROR_AUTH_ACCESS_DENIED = 1008;

async function handleErrors(response) {
	if (response.status === 401) {
		swal.fire("Unauthorised?", "User logged into another device", "error");
		localStorage.clear();
		Api().resetTracking();

		setTimeout(() => {
			window.location.href = "/";
		}, 1000);

		return null;
	}

	return response;
}

export function isResponseOffline(message) {
	return (
		message === "Failed to fetch" ||
		message === "Network Error" ||
		(message && (message.startsWith("NetworkError") || message.startsWith("Load failed")))
	);
}

var tempAlert = window.alert;

async function handleResponseJSON(response) {
	if (response) {
		const data = await response.json();

		if (data.error_code === ERROR_AUTH_PROFILE_LOCKED) {
			store.dispatch({ type: AUTH_PROFILE_LOCK, payload: true });

			window.alert = function () {};

			window.location.href = "/student/locked";

			return { message: data.message };
		} else if (data.error_code === ERROR_AUTH_ACCESS_DENIED) {
			console.log("auth access denied");
		}

		window.alert = tempAlert;

		return data;
	}

	return null;
}

export function handleAPIError(responseMessage, defaultMessage = "Some error occurred") {
	const swalOptions = {
		title: defaultMessage,
		showDenyButton: false,
		showCancelButton: false,
		confirmButtonText: "Okay",
	};

	if (isResponseOffline(responseMessage)) {
		swalOptions.title = "Your connection seems unstable. Please check your internet connection.";
	} else if (responseMessage) {
		swalOptions.title = responseMessage;
	}

	swal.fire(swalOptions);
}

const handleClearCache = () => {
	try {
		if (caches && caches.keys) {
			caches.keys().then((names) => {
				names.forEach((name) => {
					caches.delete(name);
				});
			});
		}
	} catch (error) {}
};

function Api() {
	const logout = () => {
		try {
			if (window?.Moengage?.destroy_session) window.Moengage.destroy_session();
			resetTracking();
			handleClearCache();
		} catch (error) {}

		localStorage.clear();
		window.location.href = "/";
	};

	const getUserDisplayName = () => {
		try {
			let data = localStorage.getItem("userdetail");
			data = JSON.parse(data);

			if (!data) {
				return null;
			}

			return data.user_display_name;
		} catch (e) {
			return null;
		}
	};

	const getUserName = () => {
		try {
			let data = localStorage.getItem("userdetail");
			data = JSON.parse(data);

			if (!data) {
				return null;
			}

			return data.username;
		} catch (e) {
			return null;
		}
	};

	const getUserID = () => {
		try {
			let data = localStorage.getItem("userdetail");
			data = JSON.parse(data);

			if (!data) {
				return null;
			}

			return data.user_id;
		} catch (e) {
			return null;
		}
	};

	const isAuthenticated = () => {
		try {
			let data = localStorage.getItem("userdetail");
			data = JSON.parse(data);

			if (!data) {
				return null;
			}

			return data.token;
		} catch (e) {
			return null;
		}
	};

	const doFetch = async (type, data, url) => {
		let reqData = { ...data };
		if (data === null || data === undefined) {
			reqData = {};
		}

		let token = isAuthenticated();

		return fetch(url, {
			method: type,
			headers:
				type !== "GET"
					? {
							...(token && { Authorization: `Token ${token}` }),
							"Content-Type": "Application/json",
					  }
					: {
							...(token && { Authorization: `Token ${token}` }),
					  },
			body: type !== "GET" ? JSON.stringify({ ...reqData }) : null,
		})
			.then(handleErrors)
			.then(handleResponseJSON)
			.catch((err) => {
				return {
					status: false,
					message: err?.message || "Some error occurred",
				};
			});
	};

	const doUpload = async (type, data, url) => {
		let token = isAuthenticated();

		return fetch(url, {
			method: type,
			headers:
				type !== "GET"
					? {
							Authorization: `Token ${token}`,
					  }
					: {
							Authorization: `Token ${token}`,
					  },
			body: data,
		})
			.then(handleErrors)
			.then(handleResponseJSON)
			.catch((err) => {
				return {
					status: false,
					message: err?.message || "Some error occurred",
				};
			});
	};

	const getUserData = () => {
		try {
			let data = localStorage.getItem("userData");
			data = JSON.parse(data);

			if (!data) {
				return {};
			}

			return data;
		} catch (e) {
			return {};
		}
	};

	// Identify user for sending events to segment
	const identifyUser = (tempUserData = {}) => {
		if (!process.env.REACT_APP_SEGMENT_KEY) {
			return;
		}

		let userData = {};

		if (tempUserData?.student_data) {
			userData = tempUserData;
		} else {
			userData = getUserData();
		}

		const user = userData.student_data;

		if (!user || !user.id) {
			return;
		}

		let traits = {
			mobileNumber: user.mobile_number,
			name: user.name,
		};

		if (user.email) {
			traits.email = user.email;
		}

		try {
			window.analytics.identify(user.id, traits);
		} catch (err) {}
	};

	// Track pages that user visits and send to segment
	const trackPage = (pageName) => {
		try {
			if (!process.env.REACT_APP_SEGMENT_KEY) {
				return;
			}
			if (pageName) {
				window.analytics.page(pageName);
			} else {
				window.analytics.page();
			}
		} catch (err) {}
	};

	const trackEvent = (eventName, properties = {}) => {
		if (!process.env.REACT_APP_SEGMENT_KEY) {
			return;
		}

		try {
			if (eventName) {
				window.analytics.track(eventName, properties);
			}
		} catch (error) {}
	};

	// Clear userId and other info stored for segment tracking
	const resetTracking = () => {
		try {
			if (!process.env.REACT_APP_SEGMENT_KEY) {
				return;
			}
			window.analytics.reset();
		} catch (err) {}
	};

	// Send event to MoEngage
	const moEngageTrackEvent = (eventName, properties) => {
		if (!process.env.REACT_APP_MOENGAGE_APP_ID) {
			return;
		}

		const event = new CustomEvent("MOENGAGE_EVENT", {
			detail: { eventName, properties },
		});

		window.dispatchEvent(event);
	};

	return {
		isAuthenticated,
		doFetch,
		doUpload,
		getUserID,
		getUserName,
		getUserDisplayName,
		getUserData,
		logout,
		identifyUser,
		trackPage,
		trackEvent,
		resetTracking,
		moEngageTrackEvent,
	};
}

export default Api();
