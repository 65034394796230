import React from "react";
import MyProfileImg from "../../assets/img/my_profile.svg";
import SwitchBatchImg from "../../assets/img/switch_batch.svg";
import ChangePWImg from "../../assets/img/change_pw.svg";
import HelpSupportImg from "../../assets/img/help_support.svg";
import LogoutImg from "../../assets/img/logout.svg";
import UserDropImg from "../../assets/img/user_down.svg";
import Styles from "./layout.module.css";
import { Link } from "react-router-dom";
import NotificationImg from "../../assets/img/notification.svg";
import lockedImg from "../../assets/img/lockedImg.svg";
import Api from "../../common/Api";
import Avatar from "../../assets/img/dashboard_avatar.png";
import { segmentEvents } from "../../utils/constants";

const NavLinks = ({ handleModalOpen, studentData, openNotification, isFree }) => {
	return (
		<div className="collapse navbar-collapse" id="myNavbar">
			<ul className="nav navbar-nav navbar-right" id="close">
				<div className={Styles.dropdown}>
					{isFree ? (
						<div 
							className={Styles.navbar_item}
							onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SCHEDULE_CLICK)}
						>
							<img src={lockedImg} style={{ width: 14, marginRight: "0.5rem" }} alt="" />
							Schedule
						</div>
					) : (
						<Link 
							to="/student/myschedule" 
							className={Styles.navbar_item}
							onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SCHEDULE_CLICK)}
						>
							Schedule
						</Link>
					)}
				</div>

				<div className={Styles.dropdown}>
					{isFree ? (
						<div 
							className={Styles.navbar_item} 
							onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SOLUTIONS_CLICK)}
						>
							<img src={lockedImg} style={{ width: 14, marginRight: "0.5rem" }} alt="" />
							Solutions
						</div>
					) : (
						<Link 
							to="/student/doubtmodule/searchquestion" 
							className={Styles.navbar_item} 
							onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SOLUTIONS_CLICK)}
						>
							Solutions
						</Link>
					)}
				</div>

				<div className="nav-notification" onClick={openNotification}>
					<img src={NotificationImg} alt="notification" />
				</div>

				<div className={Styles.dropdown}>
					<div className={Styles.dropbtn}>
						<img
							src={studentData.profile_pic ? studentData.profile_pic : Avatar}
							className="nav-profile-pic"
							alt="user_default"
						/>
						<p className="nav-user-name">{studentData.name.split(" ")[0]}</p>
						<img src={UserDropImg} alt="drop" />
					</div>

					<div className={Styles.dropdownContent}>
						<Link to="/student/profile" onClick={() => Api.trackEvent(segmentEvents.NAVBAR.PROFILE_CLICK)}>
							<img src={MyProfileImg} alt="profile" />
							<p className={Styles.dropdownItemsText}>Profile</p>
						</Link>

						{!isFree && (
							<div className={Styles.dropdownItems} onClick={() => {
									handleModalOpen();
									Api.trackEvent(segmentEvents.NAVBAR.SWITCHBATCH_CLICK);
								}}
							>
								<img src={SwitchBatchImg} alt="switch Batch" />
								<p className={Styles.dropdownItemsText}>Switch Batch</p>
							</div>
						)}

						<Link to="/student/changepassword" onClick= {() => Api.trackEvent(segmentEvents.NAVBAR.CHANGEPASSWORD_CLICK)}>
							<img src={ChangePWImg} alt="Change Password" />
							<p className={Styles.dropdownItemsText}>Change Password</p>
						</Link>

						<Link to="/student/help-and-support" onClick= {() => Api.trackEvent(segmentEvents.NAVBAR.HELPSUPPORT_CLICK)}>
							<img src={HelpSupportImg} alt="Help & Support" />
							<p className={Styles.dropdownItemsText}>Help & Support</p>
						</Link>

						<div 
							className={Styles.dropdownItems} 
							onClick={() => {
								Api.trackEvent(segmentEvents.NAVBAR.LOGOUT_CLICK);
								Api.logout();
							}}
						>
							<img src={LogoutImg} alt="Logout" />
							<p className={Styles.dropdownItemsText}>Logout</p>
						</div>
					</div>
				</div>
			</ul>
		</div>
	);
};

export default NavLinks;
