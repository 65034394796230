import "antd/dist/antd.css";
import "./styles/exam-screen.css";
import "./styles/header.css";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState, useCallback, useRef, createRef } from "react";
import { ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

// import MyClassroomLogoSVG from "../../images/genericV2/myclassroom-logo.svg";

import GenericV2InstructionsModal from "./common/instructions-modal";
import GenericV2MarkingSchemeModalMobile from "./common/marking-scheme-modal-mobile";
import GenericV2QuestionSingleChoice from "./common/question-single-choice";
import GenericV2QuestionInput from "./common/question-input";
import GenericV2QuestionMultipleChoice from "./common/question-multiple-choice";
import GenericV2SectionInstructionsModal from "./common/section-instructions-modal";
import GenericV2Sections from "./common/sections";
import GenericV2Timer from "./common/timer";
import GenericV2SubmitExam from "./common/submit-exam";
import Loader from "../../components/Loader";
import LoadingComp from "../../components/LoadingComp";
import {
	generateQuestionTileProps,
	padDigits,
	showToast,
	updateQuestionStatus,
	updateQuestionStatusFromMap,
} from "./common/utils/misc";
import useMediaQuery from "../../utils/hooks/use-media-query";
import Api, { handleAPIError, isResponseOffline } from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";
import GenericV2DPPSubmitModal from "./common/dpp-submit-modal";
import { getOfflineCurrentExamData, getOfflineCurrentStudentExamData, getOfflineMiscData } from "../../store/examStore";
import {
	GENERIC_OFFLINE_EXAM_STARTED,
	GENERIC_OFFLINE_UPDATE_QUESTION,
} from "../../store/reducers/examReducer/studentExamDataReducer";
import { GENERIC_OFFLINE_SET_MISC_ACTIVITY } from "../../store/reducers/examReducer/miscReducer";
import {
	GENERIC_OFFLINE_QUESTION_SAVE_FAILED,
	GENERIC_OFFLINE_QUESTION_SAVE_SUCCESSFULL,
} from "../../store/reducers/examReducer/studentAnswerDataReducer";
import { trySync } from "./offline/queue";
import { segmentEvents } from "../../utils/constants";
// import MathJax from "mathjax3-react";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//     dsn: "https://9584179556194e4689b46cef44c27ba5@o1308748.ingest.sentry.io/6554373",
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

function GenericV2SectionTableRow({ divClassName = "", title = "", value }) {
	return (
		<div className="mark-info-div">
			<table>
				<tbody>
					<tr>
						<td>
							<div className={divClassName}> {value}</div>
						</td>
						<td>
							<div style={{ paddingInline: 2 }}>{title}</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

function GenericV2ExamScreen({ isDPP = false, isOffline = false }) {
	const intervalRef = useRef(null);
	/** @type { React.RefObject<HTMLDivElement> } */
	const sectionContainerRef = useRef(null);
	/** @type { React.RefObject<HTMLDivElement> } */
	const questionContainerRef = useRef(null);
	/** @type { React.RefObject<[React.RefObject<HTMLDivElement>]> } */
	const sectionRefs = useRef([]);
	/** @type { React.RefObject<{ [refKey: string]: React.RefObject<HTMLDivElement>}> } */
	const questionRefs = useRef({});
	const mobileQuestionRef = useRef(null);
	const subscribed = useRef(true);
	const isMobile = useMediaQuery("(max-width: 767px)");
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [sectionLoading, setSectionLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	// Modal states
	const [showInstructions, setShowInstructions] = useState(false);
	const [showSectionInstructions, setShowSectionInstructions] = useState(false);
	const [showMarkingModalMobile, setShowMarkingModalMobile] = useState(false);

	const [examName, setExamName] = useState(null);
	const [submitExam, setSubmitExam] = useState(false);
	const [questionMap, setQuestionMap] = useState({});
	const [questionStatusMap, setQuestionStatusMap] = useState({});
	const [overallCounts, setOverallCounts] = useState({});
	const [sectionList, setSectionList] = useState([]);

	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [currentQuestionAttemptTime, setCurrentQuestionAttemptTime] = useState(Date.now());
	const [currentQuestionKey, setCurrentQuestionKey] = useState(null);
	const [currentSectionId, setCurrentSectionId] = useState(null);
	const [sectionIdMaxLength, setCurrentSectionIdMaxLength] = useState(2);
	const [sectionAttemptedMap, setSectionAttemptedMap] = useState({});

	const [examTimeLeft, setExamTimeLeft] = useState(null);
	const [examExpectedEndTime, setExamExpectedEndTime] = useState(null);

	const [examError, setExamError] = useState(null);

	// DPP Additionals
	const [dppTags, setDppTags] = useState([]);

	const history = useHistory();
	const location = useLocation();

	const handleNumericInput = (e) => {
		const re = /^-?[0-9]*\.?[0-9]*$/;
		const value = e.target.value;

		if (re.test(value)) {
			// Update currentQuestion
			setCurrentQuestion((q) => ({ ...q, ans_given: value }));

			// TODO: Check if need to update question link
		}
	};

	const handleSelectOption = (option) => () => {
		if (option) {
			setCurrentQuestion((q) => {
				const value = q.ans_given === option ? "" : option;

				return { ...q, ans_given: value };
			});
		}
	};

	const handleCheckOption = (option) => () => {
		if (option) {
			setCurrentQuestion((q) => {
				const key = `is_option${option}_selected`;

				const value = q[key];

				return { ...q, [key]: !value };
			});
		}
	};

	const getCurrentTestData = () => {
		if (isOffline) {
			const { currentStudentId, currentExamId } = getOfflineMiscData();

			return {
				examId: currentExamId,
				studentId: currentStudentId,
			};
		}

		return {
			examId: location.state.examdId,
			studentId: location.state.studentId,
		};
	};

	const updateSeconds = async ({ questionId, sectionId }, { scroll = false, skipUpdatingQuestionStatus = false } = {}) => {
		// Update seconds data for question
		try {
			const currentQuestionTime = Math.round((Date.now() - currentQuestionAttemptTime) / 1000);

			const { examId, studentId } = getCurrentTestData();

			const formData = new FormData();
			formData.append("student_id", studentId);

			let totalSeconds = currentQuestionTime;

			if (isOffline) {
				const studentExamData = getOfflineCurrentStudentExamData();

				if (studentExamData?.questions) {
					const { questions } = studentExamData;

					if (currentQuestionKey in questions) {
						const currentQuestionData = questions[currentQuestionKey];

						if (currentQuestionData?.current_attempt_seconds) {
							totalSeconds += currentQuestionData?.current_attempt_seconds?.reduce((pv, cv) => pv + cv, 0);
						}
					}
				}
			}

			formData.append("total_seconds", totalSeconds);

			let url;

			if (isDPP) {
				url = `${ApiUrl.SAVE_STUDENT_EXAM_QUESTION}${examId}/`;
				formData.append("allotted_question_id", questionId);
			} else {
				url = `${ApiUrl.SAVE_EXAM}${examId}/`;
				formData.append("ques_id", questionId);
			}

			// API call
			const res = await Api.doUpload("POST", formData, url);

			if (subscribed.current) {
				if (isOffline) {
					// Update offline data
					dispatch({
						type: GENERIC_OFFLINE_UPDATE_QUESTION,
						payload: {
							studentId,
							examId,
							serialNo: currentQuestion.serial_no,
							questionUpdateData: {
								total_seconds: currentQuestionTime === 0 ? 1 : currentQuestionTime,
								current_attempt_seconds: currentQuestionTime === 0 ? 1 : currentQuestionTime,
							},
						},
					});
				}
				// Update question seconds
				setCurrentQuestion((q) => ({
					...q,
					total_seconds: currentQuestionTime === 0 ? 1 : currentQuestionTime,
				}));
			}

			const questionStatusKey = `${padDigits(currentQuestion.exam_section_assoc, sectionIdMaxLength)}-${
				currentQuestion.question_bank_assoc?.id
			}`;

			if (questionStatusKey in questionStatusMap) {
				// Locally update question status
				const currentQuestionStatusMap = { ...questionStatusMap };

				const questionStatus = currentQuestionStatusMap[questionStatusKey];

				if (questionStatus) {
					showToast(questionStatus.color_id);
				}

				if (currentQuestionStatusMap[questionStatusKey]) {
					currentQuestionStatusMap[questionStatusKey] = {
						...questionStatus,
						color_id: questionStatus.color_id === "0" ? "4" : questionStatus.color_id,
					};
				}

				setQuestionStatusMap(currentQuestionStatusMap);
				setOverallCounts(updateQuestionStatusFromMap(currentQuestionStatusMap));
			}

			if (res.status === true) {
				const payloadData = res.data;
				if (!skipUpdatingQuestionStatus && subscribed.current) {
					const [questionStatusDataMap, allCounts] = updateQuestionStatus(
						payloadData.question_status_list,
						sectionIdMaxLength
					);

					setExamTimeLeft(payloadData.num_seconds);
					setQuestionStatusMap(questionStatusDataMap);
					setOverallCounts(allCounts);
				}

				if (isOffline) {
					dispatch({
						type: GENERIC_OFFLINE_QUESTION_SAVE_SUCCESSFULL,
						payload: {
							studentId,
							examId,
							questionId: currentQuestion.serial_no,
						},
					});
				}

				return true;
			} else {
				if (!subscribed.current) return false;

				if (isOffline && isResponseOffline(res.message)) {
					dispatch({
						type: GENERIC_OFFLINE_QUESTION_SAVE_FAILED,
						payload: {
							studentId,
							examId,
							questionId: currentQuestion.serial_no,
						},
					});

					return true;
				}

				handleAPIError(res?.message);
			}
		} catch (error) {
			console.log(error);
		}

		return false;
	};

	const getMoreData = async ({ subjectId, sectionId, serialNo, skipUpdatingQuestionStatus = false }) => {
		const { studentId, examId } = getCurrentTestData();

		// Load section question

		try {
			const res = await Api.doFetch(
				"GET",
				{},
				ApiUrl.FETCH_QUESTIONS + `${examId}/?student_id=${studentId}&subject_id=${subjectId}&section_id=${sectionId}`
			);
			// const res = await axios.get(
			//     `${process.env.REACT_APP_BASE_URL}test/fetch-questions/${examdId}/?student_id=${studentId}&subject_id=${subjectId}&section_id=${sectionId}`,
			//     generateRequestOptions(token)
			// );

			if (!subscribed.current) return;

			if (res.status === true) {
				const payloadData = res.data;
				let firstQuestionKey, currQuestionKey;

				let questionMapResult = {};

				const sectionQuestions = payloadData.section_questions;

				sectionQuestions.forEach((item) => {
					// Only add unique questions
					if (!firstQuestionKey) firstQuestionKey = item.serial_no;

					if (!questionMapResult[item.serial_no]) {
						questionMapResult[item.serial_no] = item;

						if (item.serial_no === serialNo) {
							// Current Question
							currQuestionKey = item.serial_no;
						}
					}
				});

				setQuestionMap((qMap) => {
					return {
						...qMap,
						...questionMapResult,
					};
				});

				if (!currQuestionKey) {
					currQuestionKey = firstQuestionKey;
				}

				if (questionMapResult[currQuestionKey]) setCurrentQuestion(questionMapResult[currQuestionKey]);
				setCurrentQuestionKey(currQuestionKey);
				setCurrentQuestionAttemptTime(Date.now());

				if (!skipUpdatingQuestionStatus) {
					setExamTimeLeft(payloadData.num_seconds);

					const questionStatusList = payloadData.question_status_list;

					const [questionStatusDataMap, allCounts] = updateQuestionStatus(questionStatusList, sectionIdMaxLength);

					setQuestionStatusMap(questionStatusDataMap);
					setOverallCounts(allCounts);
				}
			} else {
				throw new Error(res.message);
			}
		} catch (error) {
			console.log(error);

			handleAPIError(error.message);
		}
	};

	const changeSection = (sectionId, sectionIndex) => async () => {
		Api.trackEvent(segmentEvents.GENERIC.SECTION_CLICK);
		if (sectionLoading) return;

		const paddedSectionId = padDigits(sectionId, sectionIdMaxLength);

		const sectionFirstQuestionKey = Object.keys(questionStatusMap).find((qs) => qs.includes(paddedSectionId));

		if (!sectionFirstQuestionKey) {
			// sectionFirstQuestionKey not found
			return;
		}

		const { section_id, serial_no, subject_id } = questionStatusMap[sectionFirstQuestionKey];

		const currentQuestionFromMap = questionMap[serial_no];

		if (currentQuestionFromMap && currentQuestionFromMap.id === currentQuestion.id) {
			// already first question of the section selected
			return;
		}

		setSectionLoading(true);

		// Save question
		const updatedSeconds = await updateSeconds({ questionId: currentQuestion.id }, { skipUpdatingQuestionStatus: true });

		if (!updatedSeconds) {
			setSectionLoading(false);
			return;
		}

		// Change Section
		if (currentSectionId !== sectionId) {
			setCurrentSectionId(sectionId);
			if (isOffline) {
				dispatch({
					type: GENERIC_OFFLINE_SET_MISC_ACTIVITY,
					payload: {
						section: sectionId,
						question: serial_no,
					},
				});
			}
			scrollToSectionIndex(sectionIndex);
		}

		if (serial_no in questionMap) {
			setCurrentQuestion(questionMap[serial_no]);
			setCurrentQuestionKey(serial_no);
			setCurrentQuestionAttemptTime(Date.now());
		} else {
			await getMoreData({
				subjectId: subject_id,
				sectionId: section_id,
				serialNo: serial_no,
			});
		}

		if (subscribed.current) setSectionLoading(false);
	};

	const handleScrollToTop = () => {
		if (sectionContainerRef.current) {
			window.scroll({ behavior: "smooth", top: sectionContainerRef.current.clientTop, left: 0 });
		}
	};

	const handleScrollToSection = (sectionId) => {
		const currentSectionIndex = sectionList.findIndex((s) => s.id === sectionId);

		if (currentSectionIndex > -1) {
			scrollToSectionIndex(currentSectionIndex);
		}
	};

	const handleScrollToQuestion = () => {};

	const changeQuestion = (nextQuestion) => async () => {
		Api.trackEvent(segmentEvents.GENERIC.QUESTION_CLICK);
		try {
			const scrollToQuestion = isMobile;

			if (nextQuestion.serial_no === currentQuestion.serial_no) return;

			// TODO: Load next question
			if (currentSectionId === nextQuestion.section_id || nextQuestion.serial_no in questionMap) {
				// Save Question
				const updatedSeconds = await updateSeconds({ questionId: currentQuestion.id });

				if (!updatedSeconds) return;

				const nextQuestionData = questionMap[nextQuestion.serial_no];

				if (nextQuestionData) {
					setCurrentQuestion(nextQuestionData);
					setCurrentQuestionKey(nextQuestion.serial_no);
					setCurrentQuestionAttemptTime(Date.now());
				}

				if (scrollToQuestion) {
					handleScrollToQuestion();
				} else {
					handleScrollToTop();
				}
			} else {
				// Save Question
				const updatedSeconds = await updateSeconds(
					{ questionId: currentQuestion.id },
					{ skipUpdatingQuestionStatus: true }
				);

				if (!updatedSeconds) return;
				setSectionLoading(true);

				await getMoreData({
					sectionId: nextQuestion.section_id,
					subjectId: nextQuestion.subject_id,
					serialNo: nextQuestion.serial_no,
				});

				if (subscribed.current) setSectionLoading(false);

				if (intervalRef.current) clearTimeout(intervalRef.current);

				intervalRef.current = setTimeout(() => {
					if (questionContainerRef.current && questionRefs.current && currentQuestionKey) {
						const questionContainer = questionContainerRef.current;
						const questionScrollRef = questionRefs.current[currentQuestionKey];

						if (questionScrollRef && questionScrollRef) {
							const questionEl = questionScrollRef.current;

							if (questionContainer.offsetWidth < questionContainer.scrollWidth) {
								const { offsetLeft, offsetWidth } = questionEl;

								let left = offsetLeft - offsetWidth - 20;
								if (left < 0) left = 0;

								questionContainer.scrollTo({ left, behavior: "smooth" });
							}
						}
					}
				}, 150);
			}

			if (currentSectionId !== nextQuestion.section_id) {
				setCurrentSectionId(nextQuestion.section_id);

				handleScrollToSection(nextQuestion.section_id);
			}

			if (isOffline) {
				dispatch({
					type: GENERIC_OFFLINE_SET_MISC_ACTIVITY,
					payload: {
						section: nextQuestion.section_id,
						question: nextQuestion.serial_no,
					},
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const updateAnswer = async ({ save = true, review = false } = {}) => {
		try {
			const { studentId, examId } = getCurrentTestData();

			if (!studentId) return;

			const formData = new FormData();
			const updateData = {};

			const currentQuestionTime = Math.round((Date.now() - currentQuestionAttemptTime) / 1000);

			updateData.review = review;
			updateData.review_status = review ? "true" : "false";
			updateData.total_seconds = currentQuestionTime === 0 ? 1 : currentQuestionTime;

			if (isOffline) {
				const studentExamData = getOfflineCurrentStudentExamData();

				if (studentExamData?.questions) {
					const { questions } = studentExamData;

					if (currentQuestionKey in questions) {
						const currentQuestionData = questions[currentQuestionKey];

						if (currentQuestionData?.current_attempt_seconds) {
							updateData.total_seconds += currentQuestionData?.current_attempt_seconds?.reduce(
								(pv, cv) => pv + cv,
								0
							);
						}
					}
				}
			}

			formData.append("student_id", studentId);
			formData.append("total_seconds", updateData.total_seconds);
			formData.append("review_status", review);

			const questionType = currentQuestion.question_type_id;
			let answered = false;

			if (questionType === "1" || questionType === "3") {
				if (save) {
					formData.append("ans_given", currentQuestion.ans_given);

					updateData.ans_given = currentQuestion.ans_given;

					if (currentQuestion.ans_given) {
						answered = true;
					}
				}
			}

			if (questionType === "2" && save) {
				["1", "2", "3", "4"].forEach((num) => {
					const optKey = `is_option${num}_selected`;
					const optAns = currentQuestion[optKey];

					formData.append(optKey, optAns);
					updateData[optKey] = optAns;

					if (optAns && !answered) {
						answered = true;
					}
				});
			}

			let questionStatusColor = null;
			const paddedSectionId = padDigits(currentSectionId, sectionIdMaxLength);
			const questionAssocId = currentQuestion?.question_bank_assoc?.id;
			const questionStatusKey = `${paddedSectionId}-${questionAssocId}`;

			formData.append("student_id", studentId);

			let saveExamURL;

			if (isDPP) {
				saveExamURL = `${ApiUrl.SAVE_STUDENT_EXAM_QUESTION}${examId}/`;
				formData.append("allotted_question_id", currentQuestion.id);
			} else {
				saveExamURL = `${ApiUrl.SAVE_EXAM}${examId}/`;
				formData.append("ques_id", currentQuestion.id);
			}

			if (answered)
				if (review) questionStatusColor = "3";
				else questionStatusColor = "1";
			else if (review) questionStatusColor = "2";
			else questionStatusColor = "4";

			if (isOffline) {
				const attemptedData = { ...sectionAttemptedMap };

				let { attempted, canAttempt, total } = attemptedData[currentSectionId] || {};

				if (attempted && +canAttempt > 0) {
					if (answered) {
						attempted = { ...attempted, [currentQuestionKey]: true };
					} else if (attempted[currentQuestionKey]) {
						delete attempted[currentQuestionKey];
					}

					const attemptedCount = Object.keys(attempted).length;

					if (answered && attemptedCount > canAttempt) {
						Swal.fire({
							title: `You can attempt only ${canAttempt} out of ${total} questions in this section. You have already attempted ${canAttempt} question${
								canAttempt === 1 ? "" : "s"
							}. Please deselect any marked question to continue.`,
							showDenyButton: false,
							showCancelButton: false,
							confirmButtonText: "Okay",
						});

						return false;
					}

					attemptedData[currentSectionId] = {
						attempted,
						canAttempt,
						total,
					};

					setSectionAttemptedMap(attemptedData);
				}
			}

			updateData.current_attempt_seconds = currentQuestionTime === 0 ? 1 : currentQuestionTime;
			updateData.is_attempted = answered;

			if (isOffline) {
				// Update offline data
				dispatch({
					type: GENERIC_OFFLINE_UPDATE_QUESTION,
					payload: {
						studentId,
						examId,
						serialNo: currentQuestion.serial_no,
						questionUpdateData: updateData,
						answered,
						questionStatusColor,
					},
				});

				// Update state data
				setQuestionMap((qMap) => {
					const qKey = currentQuestion.serial_no;
					const qData = { ...qMap[qKey], ...updateData };

					return { ...qMap, [qKey]: qData };
				});

				if (questionStatusKey in questionStatusMap) {
					// Locally update question status
					if (questionStatusColor !== null) {
						const currentQuestionStatusMap = { ...questionStatusMap };

						const questionStatus = currentQuestionStatusMap[questionStatusKey];

						if (currentQuestionStatusMap[questionStatusKey]) {
							currentQuestionStatusMap[questionStatusKey] = {
								...questionStatus,
								color_id: questionStatusColor,
							};
						}

						setQuestionStatusMap(currentQuestionStatusMap);
						setOverallCounts(updateQuestionStatusFromMap(currentQuestionStatusMap));
					}
				}
			}

			// API call
			const res = await Api.doUpload("POST", formData, saveExamURL);

			// const res = await axios.post(`${process.env.REACT_APP_BASE_URL}test/save-exam-questions/${examdId}/`, formData, generateRequestOptions(token));

			if (!subscribed.current) return;

			showToast(questionStatusColor);

			if (res?.status === true) {
				// Add saved answer to questionMap[serial_no]
				if (!isOffline) {
					// Update question time
					setQuestionMap((qMap) => {
						const qKey = currentQuestion.serial_no;
						const qData = { ...qMap[qKey], ...updateData };

						return { ...qMap, [qKey]: qData };
					});

					if (questionStatusKey in questionStatusMap) {
						// Locally update question status
						if (questionStatusColor !== null) {
							setQuestionStatusMap((s) => ({
								...s,
								[questionStatusKey]: { ...s[questionStatusKey], color_id: questionStatusColor },
							}));
						}
					}
				}
				const payloadData = res.data;

				if (payloadData) {
					// Update timer
					setExamTimeLeft(payloadData.num_seconds);

					// Refresh questionStatusMap
					const [questionStatusDataMap, allCounts] = updateQuestionStatus(
						payloadData.question_status_list,
						sectionIdMaxLength
					);
					if (questionStatusColor === null) setQuestionStatusMap(questionStatusDataMap);
					setOverallCounts(allCounts);

					if (isOffline) {
						dispatch({
							type: GENERIC_OFFLINE_QUESTION_SAVE_SUCCESSFULL,
							payload: {
								studentId,
								examId,
								questionId: currentQuestion.serial_no,
							},
						});
					}

					return true;
				}
			} else {
				if (!subscribed.current) return false;

				if (isOffline && isResponseOffline(res.message)) {
					dispatch({
						type: GENERIC_OFFLINE_QUESTION_SAVE_FAILED,
						payload: {
							studentId,
							examId,
							questionId: currentQuestion.serial_no,
						},
					});

					return true;
				}

				handleAPIError(res?.message);
			}
		} catch (error) {
			console.log(error);
		}

		return false;
	};

	const saveAnswer = async (review = false) => {
		// Save answer
		const answerUpdated = await updateAnswer({ save: true, review });

		if (!answerUpdated) return;

		// Switch section if changed
		const nextSerialNo = String(parseInt(currentQuestion.serial_no) + 1);
		const nextQuestionData = questionMap[nextSerialNo];

		if (nextQuestionData) {
			// Question is loaded, we can switch
			setCurrentQuestion(nextQuestionData);
			setCurrentQuestionAttemptTime(Date.now());
			setCurrentQuestionKey(nextQuestionData.serial_no);
			setCurrentSectionId(nextQuestionData.exam_section_assoc);

			if (isOffline) {
				dispatch({
					type: GENERIC_OFFLINE_SET_MISC_ACTIVITY,
					payload: {
						section: nextQuestionData.exam_section_assoc,
						question: nextQuestionData.serial_no,
					},
				});
			}

			handleScrollToTop();
		} else {
			// Check next section
			let nextSection = null;
			const currentSectionIndex = sectionList.findIndex((s) => s.id === currentSectionId);

			if (currentSectionIndex > -1 && currentSectionIndex < sectionList.length - 1) {
				nextSection = sectionList[currentSectionIndex + 1];
			} else {
				// Currently at last question, go to first question
				const nextQuestionData = questionMap[1];

				if (nextQuestionData) {
					setCurrentQuestion(nextQuestionData);
					setCurrentQuestionAttemptTime(Date.now());
					setCurrentQuestionKey(nextQuestionData.serial_no);
					setCurrentSectionId(nextQuestionData.exam_section_assoc);

					if (isOffline) {
						dispatch({
							type: GENERIC_OFFLINE_SET_MISC_ACTIVITY,
							payload: {
								section: nextQuestionData.exam_section_assoc,
								question: nextQuestionData.serial_no,
							},
						});
					}

					handleScrollToTop();
				} else {
					// console.log("Some error occured, this shouldn't happen")
				}

				return;
			}

			if (nextSection) {
				const paddedSectionId = padDigits(nextSection.id, sectionIdMaxLength);
				const sectionFirstQuestionKey = Object.keys(questionStatusMap).find((qs) => qs.includes(paddedSectionId));

				if (sectionFirstQuestionKey in questionStatusMap) {
					const nextQuestion = questionStatusMap[sectionFirstQuestionKey];

					setSectionLoading(true);
					setCurrentSectionId(nextQuestion.section_id);

					await getMoreData({
						sectionId: nextQuestion.section_id,
						subjectId: nextQuestion.subject_id,
						serialNo: nextQuestion.serial_no,
					});

					if (isOffline) {
						dispatch({
							type: GENERIC_OFFLINE_SET_MISC_ACTIVITY,
							payload: {
								section: nextSection.id,
								question: nextQuestion.serial_no,
							},
						});
					}

					handleScrollToTop();

					if (subscribed.current) setSectionLoading(false);
				}
			}
		}
	};

	const handleMarkForReview = () => {
		Api.trackEvent(segmentEvents.GENERIC.MARKREVIEW_CLICK);
		saveAnswer(true);
	};

	const handleGoNext = () => {
		Api.trackEvent(segmentEvents.GENERIC.NEXT_CLICK);
		saveAnswer();
	};

	const handleTimeEnd = useCallback(() => {
		setExamTimeLeft(0);

		setSubmitExam(true);
	}, []);

	const handleSubmitExam = async () => {
		Api.trackEvent(segmentEvents.GENERIC.SUBMIT_CLICK);
		const timeLeft = Math.round((examExpectedEndTime - Date.now()) / 1000);

		setExamTimeLeft(timeLeft > 0 ? timeLeft : 0);

		setSubmitExam(true);
	};

	const handleSubmitExamConfirm = async () => {
		Api.trackEvent(segmentEvents.GENERIC.SUBMIT_YES);
		if (submitLoading) return;

		try {
			await trySync();
		} catch (error) {
			console.log("Failed to sync");
		}

		setSubmitLoading(true);

		try {
			const { studentId, examId } = getCurrentTestData();

			const formData = new FormData();
			formData.append("student_id", studentId);

			const res = await Api.doUpload("POST", formData, `${ApiUrl.SUBMIT_EXAM}${examId}/`);

			if (res.status === true) {
				if (subscribed.current) {
					if (isDPP) {
						history.replace({
							pathname: "/student/dpp-list/all",
						});
					} else {
						history.replace({
							pathname: "/student/SubmitSuccessfully",
						});
					}
				}
			} else {
				handleAPIError(res.message, "Couldn't submit exam, please try again");
			}
		} catch (error) {
			console.log(error);
			if (!subscribed.current) return;

			const swalOptions = {
				title: "Some error occured",
				showDenyButton: false,
				showCancelButton: false,
				confirmButtonText: "Okay",
			};

			if (error?.response?.payload?.Error) {
				swalOptions.title = error.response.payload.Error;
				swalOptions.width = 600;
				swalOptions.padding = "3rem";
			}

			Swal.fire(swalOptions);
		} finally {
			if (subscribed.current) setSubmitLoading(false);
		}
	};

	const handleCancelSubmit = () => {
		Api.trackEvent(segmentEvents.GENERIC.SUBMIT_NO);
		if (isDPP) {
			setSubmitExam(false);
			return;
		}

		const timeLeft = Math.round((examExpectedEndTime - Date.now()) / 1000);

		if (timeLeft > 0) {
			setExamTimeLeft(timeLeft);
			setSubmitExam(false);
		} else {
			showToast(5);
		}
	};

	const scrollToSectionIndex = (sectionIndex) => {
		if (sectionRefs.current) {
			const sectionRef = sectionRefs.current[sectionIndex];

			if (sectionRef && sectionRef.current && sectionContainerRef.current) {
				const sectionsContainer = sectionContainerRef.current;

				if (sectionsContainer.offsetWidth < sectionsContainer.scrollWidth) {
					const { offsetLeft, offsetWidth } = sectionRef.current;

					sectionsContainer.scrollTo({ left: offsetLeft - offsetWidth / 2, behavior: "smooth" });
				}
			}
		}
	};

	useEffect(() => {
		subscribed.current = true;

		return () => {
			subscribed.current = false;
		};
	}, []);

	useEffect(() => {
		if (!isDPP) {
			// Prevent going back
			window.addEventListener("popstate", (e) => {
				e.preventDefault();
				history.go(1);
			});

			// Prevent right click
			document.addEventListener("contextmenu", (event) => {
				event.preventDefault();
			});
		}

		// Start exam
		if (isOffline) {
			const goBack = (key, { examId } = {}, replace = true) => {
				const redirectPath = {
					test: "/student/objectivetest",
					instructions: `/student/generic/${examId}/instructions`,
					home: "/student",
				};

				history[replace ? "replace" : "push"](redirectPath[key] || redirectPath["home"]);
			};

			const startOfflineExam = async () => {
				try {
					const examInfo = getOfflineCurrentExamData();
					const studentExamData = getOfflineCurrentStudentExamData();

					if (examInfo?.examData && studentExamData) {
						const examData = examInfo.examData;

						setExamName(examData.exam_name);

						const examId = examData.id;

						let examEndOn = studentExamData.studentExamEndOn;

						let t0 = new Date().valueOf();
						let [t1, t2, t3] = [null, null, null];
						let offset = null;

						const examStartedRes = await Api.doFetch("POST", {}, `${ApiUrl.START_EXAM_V2}/${examId}`);

						if (examStartedRes?.status === true) {
							const examStartedData = examStartedRes.data;

							t1 = new Date(examStartedData.current_time).valueOf();
							t2 = new Date(examStartedData.current_time).valueOf();
							t3 = new Date().valueOf();

							offset = (t1 - t0 + (t2 - t3)) / 2;

							examEndOn = examStartedData.exam_end_on;

							dispatch({
								type: GENERIC_OFFLINE_EXAM_STARTED,
								payload: {
									studentExamEndOn: examEndOn,
									...getCurrentTestData(),
								},
							});
						} else if (isResponseOffline(examStartedRes?.message)) {
							handleAPIError(examStartedRes.message);
							setExamError(true);
							return;
						}

						const { currentSection, currentQuestion } = getOfflineMiscData() || {};
						const { questions, questionsStatus, sectionQuestions } = studentExamData;

						let currentDate = Date.now();

						if (offset) {
							currentDate += offset;
						}

						const numSeconds = Math.round((new Date(examEndOn).valueOf() - currentDate) / 1000);

						if (numSeconds <= 0) {
							Swal.fire({
								title: "Exam time is over",
								showDenyButton: false,
								showCancelButton: false,
								confirmButtonText: "Okay",
								allowEscapeKey: false,
								allowOutsideClick: false,
							});

							setTimeout(() => {
								goBack("test");
							}, 750);

							return;
						}

						setExamTimeLeft(numSeconds);
						setExamExpectedEndTime(examData.exam_end_on);

						if (!questions || !sectionQuestions) {
							return goBack("instructions", { examId });
						}

						// Load all questions
						setQuestionMap(questions);

						const examSectionsList = examInfo?.examSectionsList;
						const examSections = examInfo?.examSections;

						if (!examSectionsList || examSectionsList.length === 0 || !examSections) {
							return goBack("instructions", { examId });
						}

						let largestSectionId = -1;
						const allSectionsList = examSectionsList.map((s) => {
							const section = examSections[s];

							largestSectionId = Math.max(largestSectionId, section.id);

							return section;
						});

						const _sectionIdMaxLength = String(largestSectionId).length;
						setCurrentSectionIdMaxLength(_sectionIdMaxLength);
						setSectionList(allSectionsList);

						for (const { serial_no } of questionsStatus) {
							if (!(serial_no in questionRefs.current)) {
								questionRefs.current[serial_no] = createRef();
							}
						}

						const [questionStatusDataMap, allCounts] = updateQuestionStatus(questionsStatus, _sectionIdMaxLength);

						setQuestionStatusMap(questionStatusDataMap);
						setOverallCounts(allCounts);

						if (currentSection) {
							// TODO: Load that section
							const sectionIndex = examSectionsList.indexOf(currentSection);

							if (sectionIndex < 0) {
								return goBack("instructions", { examId });
							}

							setCurrentSectionId(currentSection);
							setTimeout(() => {
								scrollToSectionIndex(sectionIndex);
							}, 150);

							if (currentQuestion) {
								// TODO: Load that question
								setCurrentQuestion(questions[currentQuestion]);
								setCurrentQuestionKey(currentQuestion);
								setCurrentQuestionAttemptTime(Date.now());
							} else {
								// TODO: Load the first question for that section
								const firstSectionQuestions = sectionQuestions[currentSection];
								const firstQuestion = firstSectionQuestions[0];

								const questionData = questions[firstQuestion];

								if (!questionData) {
									return goBack("instructions", { examId });
								}

								setCurrentQuestion(questions[currentQuestion]);
								setCurrentQuestionKey(currentQuestion);
								setCurrentQuestionAttemptTime(Date.now());
							}
						} else {
							const firstSection = examSectionsList[0];

							// Load first section
							setCurrentSectionId(firstSection);

							const firstSectionQuestions = sectionQuestions[firstSection];
							const firstQuestion = firstSectionQuestions[0];

							const questionData = questions[firstQuestion];

							if (!questionData) {
								return goBack("instructions", { examId });
							}

							// Load first question
							setCurrentQuestion(questionData);
							setCurrentQuestionKey(firstQuestion);
							setCurrentQuestionAttemptTime(Date.now());
						}
					} else {
						return goBack("instructions");
					}
				} catch (error) {
					console.log(error);
					return goBack("instructions");
				} finally {
					if (subscribed.current) setLoading(false);
				}
			};

			startOfflineExam();

			return;
		}

		const startExam = async () => {
			if (!location.state) {
				history.replace("/student");
				return;
			}

			setExamName(location.state.exam_name);

			setLoading(true);

			try {
				let url;
				const formData = new FormData();

				if (isDPP) {
					url = `${ApiUrl.DPP_EXAM_BY_LIVE_CLASS}${location.state.studentId}/`;
					formData.append("exam_id", location.state.examdId);
				} else {
					url = `${ApiUrl.START_EXAM}${location.state.examdId}/`;
					formData.append("student_id", location.state.studentId);
				}

				const res = await Api.doUpload("POST", formData, url);
				// const res = await axios.post(
				//     `${process.env.REACT_APP_BASE_URL}test/start-exam/${location.state.examdId}/`,
				//     formData,
				//     generateRequestOptions(token)
				// );

				if (res.status === true) {
					const payloadData = res.data;
					if (payloadData && subscribed.current) {
						// Initialize timer
						setExamTimeLeft(payloadData.num_seconds);

						if (isDPP) {
							setDppTags(payloadData?.live_classes?.tags || []);
						}

						// setExamTimeLeft(0);

						const sectionQuestions = payloadData.section_questions;
						const currentQuestionId = payloadData.curr_question_assoc_id;
						const questionStatusList = payloadData.question_status_list;

						let currentQuestionData = null;
						let currQuestionKey;

						if (sectionQuestions) {
							// List of questions
							const questionMapResult = {};
							let firstQuestionKey;

							sectionQuestions.forEach((item) => {
								// Only add unique questions
								if (!firstQuestionKey) {
									firstQuestionKey = item.serial_no;
								}

								if (!questionMapResult[item.serial_no]) {
									questionMapResult[item.serial_no] = item;

									if (item.id === currentQuestionId) {
										// Current Question
										currentQuestionData = item;
										currQuestionKey = item.serial_no;
									}
								}
							});

							setQuestionMap(questionMapResult);

							if (!currQuestionKey) {
								// No question selected
								if (questionMapResult[firstQuestionKey]) setCurrentQuestion(questionMapResult[firstQuestionKey]);
								setCurrentQuestionKey(firstQuestionKey);
								setCurrentQuestionAttemptTime(Date.now());
							} else {
								setCurrentQuestion(currentQuestionData);
								setCurrentQuestionKey(currQuestionKey);
								setCurrentQuestionAttemptTime(Date.now());
							}

							// List of sections
							const sectionListResult = [];
							const subjects = location.state.exam_subjects;
							let largestSectionId = -1;

							subjects.forEach((subject) => {
								subject.exam_section_subject_list &&
									subject.exam_section_subject_list.forEach((section) => {
										sectionListResult.push(section);
										largestSectionId = Math.max(largestSectionId, section.id);
									});
							});

							// Max length of section id
							// Example: Max = 10000 -> 5
							// Used to pad numbers like 999 -> "00999"
							const _sectionIdMaxLength = String(largestSectionId).length;
							setCurrentSectionIdMaxLength(_sectionIdMaxLength);

							setSectionList(sectionListResult);

							// Current Section
							setCurrentSectionId(payloadData.exam_section_assoc_id);

							for (const { serial_no } of questionStatusList) {
								if (!(serial_no in questionRefs.current)) {
									questionRefs.current[serial_no] = createRef();
								}
							}

							const [questionStatusDataMap, allCounts] = updateQuestionStatus(
								questionStatusList,
								_sectionIdMaxLength
							);

							setQuestionStatusMap(questionStatusDataMap);
							setOverallCounts(allCounts);
						}
					}
				} else {
					throw new Error(res.message);
				}

				// if (res.payload) {
				//     const payloadData = res.payload.data;

				//     if (payloadData && subscribed.current) {
				//         // Initialize timer
				//         setExamTimeLeft(payloadData.num_seconds);
				//         // setExamTimeLeft(0);

				//         const sectionQuestions = payloadData.section_questions;
				//         const currentQuestionId = payloadData.curr_question_assoc_id;
				//         const questionStatusList = payloadData.question_status_list;

				//         let currentQuestionData = null;
				//         let currQuestionKey;

				//         if (sectionQuestions) {
				//             // List of questions
				//             const questionMapResult = {};
				//             let firstQuestionKey;

				//             sectionQuestions.forEach((item) => {
				//                 // Only add unique questions
				//                 if (!firstQuestionKey) {
				//                     firstQuestionKey = item.serial_no;
				//                 }

				//                 if (!questionMapResult[item.serial_no]) {
				//                     questionMapResult[item.serial_no] = item;

				//                     if (item.id === currentQuestionId) {
				//                         // Current Question
				//                         currentQuestionData = item;
				//                         currQuestionKey = item.serial_no;
				//                     }
				//                 }
				//             });

				//             setQuestionMap(questionMapResult);

				//             if (!currQuestionKey) {
				//                 // No question selected
				//                 setCurrentQuestion(questionMapResult[firstQuestionKey]);
				//                 setCurrentQuestionKey(firstQuestionKey);
				//                 setCurrentQuestionAttemptTime(Date.now());
				//             } else {
				//                 setCurrentQuestion(currentQuestionData);
				//                 setCurrentQuestionKey(currQuestionKey);
				//                 setCurrentQuestionAttemptTime(Date.now());
				//             }

				//             // List of sections
				//             const sectionListResult = [];
				//             const subjects = location.state.exam_subjects;
				//             let largestSectionId = -1;

				//             subjects.forEach((subject) => {
				//                 subject.exam_section_subject_list &&
				//                     subject.exam_section_subject_list.forEach((section) => {
				//                         sectionListResult.push(section);
				//                         largestSectionId = Math.max(largestSectionId, section.id);
				//                     });
				//             });

				//             // Max length of section id
				//             // Example: Max = 10000 -> 5
				//             // Used to pad numbers like 999 -> "00999"
				//             const _sectionIdMaxLength = String(largestSectionId).length;
				//             setCurrentSectionIdMaxLength(_sectionIdMaxLength);

				//             setSectionList(sectionListResult);

				//             // Current Section
				//             setCurrentSectionId(payloadData.exam_section_assoc_id);

				//             for (const { serial_no } of questionStatusList) {
				//                 if (!(serial_no in questionRefs.current)) {
				//                     questionRefs.current[serial_no] = createRef();
				//                 }
				//             }

				//             const [questionStatusDataMap, allCounts] = updateQuestionStatus(questionStatusList, _sectionIdMaxLength);

				//             setQuestionStatusMap(questionStatusDataMap);
				//             setOverallCounts(allCounts);
				//         }
				//     }
				// }
			} catch (error) {
				console.log(error);

				if (!subscribed.current) return;

				const swalOptions = {
					title: error.message || "Some error occured",
					showDenyButton: false,
					showCancelButton: false,
					confirmButtonText: "Okay",
					allowEscapeKey: false,
					allowOutsideClick: false,
				};

				if (error?.response?.payload?.Error) {
					swalOptions.title = error?.response?.payload?.Error;
					swalOptions.padding = "3rem";
				}

				if (swalOptions.title === "Exam time over") {
					swalOptions.title = "Exam time is over";
				}

				// Swal.fire(swalOptions).then(() => {
				//     history.push("/");
				// });
			} finally {
				if (subscribed.current) setLoading(false);
			}
		};

		startExam();

		return () => {
			if (!isDPP) {
				window.removeEventListener("popstate", function () {});
				document.removeEventListener("contextmenu", function () {});
			}
		};
	}, [history, location, isDPP, isOffline]);

	useEffect(() => {
		if (isOffline && currentSectionId !== null) {
			const examInfo = getOfflineCurrentExamData();
			const studentExamData = getOfflineCurrentStudentExamData();

			if (examInfo && studentExamData) {
				const { examSections } = examInfo;
				const { questions, sectionQuestions } = studentExamData;

				let canAttemptCount = 0;
				let totalQuestions = 0;
				let attemptedMap = {};

				const sectionData = examSections[currentSectionId];
				const sectionQuestionsList = sectionQuestions[currentSectionId];

				if (sectionData) {
					canAttemptCount = +sectionData.num_compulsory_questions;
					totalQuestions = +sectionData.num_of_questions;
				}

				sectionQuestionsList.forEach((quesId) => {
					const questionData = questions[quesId];

					if (questionData?.is_attempted) {
						attemptedMap[questionData.serial_no] = true;
					}
				});

				setSectionAttemptedMap((sac) => ({
					...sac,
					[currentSectionId]: {
						attempted: attemptedMap,
						canAttempt: canAttemptCount,
						total: totalQuestions,
					},
				}));
			}
		}
	}, [isOffline, currentSectionId]);

	useEffect(() => {
		if (examTimeLeft > 0) {
			let expectedEndDate = Date.now() + examTimeLeft * 1000;
			expectedEndDate = Math.round(expectedEndDate / 1000) * 1000;

			setExamExpectedEndTime(expectedEndDate);
		}
	}, [examTimeLeft]);

	useEffect(() => {
		sectionRefs.current = Array.from({ length: sectionList.length }, (_, i) => {
			return sectionRefs.current[i] || createRef();
		});
	}, [sectionList]);

	useEffect(() => {
		if (intervalRef.current) clearTimeout(intervalRef.current);

		intervalRef.current = setTimeout(() => {
			if (questionContainerRef.current && questionRefs.current && currentQuestionKey) {
				const questionContainer = questionContainerRef.current;
				const questionScrollRef = questionRefs.current[currentQuestionKey];

				if (questionScrollRef && questionScrollRef.current) {
					const questionEl = questionScrollRef.current;

					if (questionContainer.offsetWidth < questionContainer.scrollWidth) {
						const { offsetLeft, offsetWidth } = questionEl;

						let left = offsetLeft - offsetWidth - 20;
						if (left < 0) left = 0;

						questionContainer.scrollTo({ left, behavior: "smooth" });
					}
				}
			}
		}, 150);

		return () => {
			if (intervalRef.current) clearTimeout(intervalRef.current);
		};
	}, [currentQuestionKey]);

	if (loading) return <LoadingComp />;

	if (examError)
		return (
			<div>
				<nav className="nav-bar">
					<div>
						<div className="genericV2-myclassroom-logo"></div>
					</div>
					<div className="nav-bar-right"></div>
				</nav>

				<div
					className="container genericV2-instructions"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						fontSize: "2rem",
						flexDirection: "column",
					}}>
					There was an error loading the exam, please refresh and try again
				</div>
			</div>
		);

	if (!isDPP && submitExam)
		return (
			<>
				<ToastContainer
					position="bottom-center"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>

				<GenericV2SubmitExam
					handleSubmitExamConfirm={handleSubmitExamConfirm}
					handleCancelSubmit={handleCancelSubmit}
					questionCount={overallCounts.questionCount}
					answeredCount={overallCounts.answeredCount}
					unansweredCount={
						overallCounts.unansweredCount - (currentQuestion && (currentQuestion.total_seconds === 0) === 0 ? 1 : 0)
					}
					answeredReviewCount={overallCounts.answeredReviewCount}
					unansweredReviewCount={overallCounts.unansweredReviewCount}
					notVisitedCount={
						overallCounts.notVisitedCount + (currentQuestion && (currentQuestion.total_seconds === 0) === 0 ? 1 : 0)
					}
					examTimeLeft={examTimeLeft}
					isOffline={isOffline}
				/>
			</>
		);

	return (
		<>
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<nav className="nav-bar">
				<div>
					{/* <img className="nav-logo-img" src={MyClassroomLogoSVG} alt="myclassroom-logo" /> */}
					<div className="genericV2-myclassroom-logo"></div>
				</div>
				<div className="nav-bar-right">
					{isDPP ? (
						<GenericV2DPPSubmitModal
							show={submitExam}
							setShow={setSubmitExam}
							handleSubmitExamConfirm={handleSubmitExamConfirm}
							handleCancelSubmit={handleCancelSubmit}
						/>
					) : (
						<>
							<GenericV2Timer examTimeLeft={examTimeLeft} onTimerEnd={handleTimeEnd} />

							<button onClick={handleSubmitExam} className="btn submit-btn" style={{ alignSelf: "center" }}>
								SUBMIT
							</button>
						</>
					)}
					&nbsp;&nbsp;
					<span className="info ml-2" style={{ alignSelf: "center" }}>
						<GenericV2InstructionsModal isOffline={isOffline} show={showInstructions} setShow={setShowInstructions} />
					</span>
				</div>
			</nav>

			<div className="container generic_exam_container" style={{ marginTop: "20px", marginBottom: "20px" }}>
				<p className="test-name blue-text">
					<strong>{examName}</strong>
				</p>
				{isDPP && (
					<div className="tags scroll">
						<span className="tag">DPP</span> &nbsp;
						{dppTags.map((item, index) => {
							return (
								<React.Fragment key={`dpp-&tag-${index}`}>
									<span className="tag">{item.tag_name}</span> &nbsp;
								</React.Fragment>
							);
						})}
					</div>
				)}
				<div className="sections scroll-2 generic_exam_sections" ref={sectionContainerRef}>
					<GenericV2Sections
						sections={sectionList}
						currentSectionId={currentSectionId}
						changeSection={changeSection}
						ref={sectionRefs}
					/>
				</div>

				{sectionLoading ? (
					<Loader />
				) : (
					<div className="exam row generic_exam">
						<div className="col-md-7">
							<p className="text-right mobile-marking-scheme">
								<GenericV2SectionInstructionsModal
									sections={sectionList}
									currentSectionId={currentSectionId}
									show={showSectionInstructions}
									setShow={setShowSectionInstructions}
								/>
							</p>
							{/* <MathJax.Provider
								options={{
									tex2jax: {
										inlineMath: [
											["$", "$"],
											["\\(", "\\)"],
										],
									},
									"HTML-CSS": {
										linebreaks: { automatic: true, width: "container" },
									},
								}}> */}
								{currentQuestion &&
									({
										1: (
											<GenericV2QuestionSingleChoice
												currentQuestion={currentQuestion}
												handleSelectOption={handleSelectOption}
												isDPP={isDPP}
											/>
										),
										2: (
											<GenericV2QuestionMultipleChoice
												currentQuestion={currentQuestion}
												handleCheckOption={handleCheckOption}
												isDPP={isDPP}
											/>
										),
										3: (
											<GenericV2QuestionInput
												currentQuestion={currentQuestion}
												handleNumericInput={handleNumericInput}
												isDPP={isDPP}
											/>
										),
									}[currentQuestion.question_bank_assoc.question_type_id] ||
										null)}
							{/* </MathJax.Provider> */}
							<div className="exam-below-btn-container row">
								<div className="mark4revbtncon">
									<center>
										<button onClick={handleMarkForReview} className="btn mark-4-rev-btn">
											Mark for Review
										</button>
									</center>
								</div>
								<div className=" nextbtncon">
									<center>
										<button onClick={handleGoNext} className="btn mark-4-rev-btn-2">
											Next
										</button>
									</center>
								</div>
							</div>
						</div>

						<div className="col-md-4 right-panel">
							<p className="text-right">
								<GenericV2SectionInstructionsModal
									sections={sectionList}
									currentSectionId={currentSectionId}
									show={showSectionInstructions}
									setShow={setShowSectionInstructions}
									onlyButton
								/>
							</p>

							<div className="marks-info-box">
								<GenericV2SectionTableRow
									divClassName="not-visited-div-1"
									value={
										overallCounts.notVisitedCount -
										(currentQuestion && (currentQuestion.total_seconds === 0) === 0 ? 1 : 0)
									}
									title="Not Visited"
								/>
								<GenericV2SectionTableRow
									divClassName="not-answered-active-div-1"
									value={
										overallCounts.unansweredCount +
										(currentQuestion && (currentQuestion.total_seconds === 0) === 0 ? 1 : 0)
									}
									title="Not Answered"
								/>
								<GenericV2SectionTableRow
									divClassName="answered-active-div-1"
									value={overallCounts.answeredCount}
									title="Answered"
								/>
								<GenericV2SectionTableRow
									divClassName="not-ans-mkd-active-div-1"
									value={overallCounts.unansweredReviewCount}
									title="Not Answered & Marked For Review"
								/>
								<GenericV2SectionTableRow
									divClassName="ans-mkd-active-div-1"
									value={overallCounts.answeredReviewCount}
									title="Answered & Marked For Review"
								/>
							</div>

							<div className="navigation-pane" style={{ marginTop: "10px" }}>
								<p className="text-center">
									<strong>Navigation</strong>
								</p>
								<div className="all-questions">
									{Object.entries(questionStatusMap).map(([key, value]) => {
										if (!value) return null;

										return (
											<div
												key={key}
												onClick={changeQuestion(value)}
												{...generateQuestionTileProps(
													value,
													currentSectionId,
													currentQuestion && currentQuestion.serial_no
												)}
												ref={
													currentQuestion && value.serial_no === currentQuestion.serial_no
														? mobileQuestionRef
														: null
												}>
												{value.serial_no}
											</div>
										);
									})}
								</div>
							</div>
						</div>
						<div className="col-md-4 phone-panel">
							<div className="scroll-3" ref={questionContainerRef}>
								{Object.entries(questionStatusMap).map(([key, value]) => {
									return (
										<div
											key={key}
											onClick={changeQuestion(value)}
											{...generateQuestionTileProps(
												value,
												currentSectionId,
												currentQuestion && currentQuestion.serial_no
											)}
											ref={(ref) => (questionRefs.current[value.serial_no].current = ref)}>
											{value.serial_no}
										</div>
									);
								})}
							</div>

							<div className="text-right">
								<GenericV2MarkingSchemeModalMobile
									show={showMarkingModalMobile}
									setShow={setShowMarkingModalMobile}
									answeredCount={overallCounts.answeredCount}
									unansweredCount={
										overallCounts.unansweredCount +
										(currentQuestion && currentQuestion.total_seconds === 0 ? 1 : 0)
									}
									answeredReviewCount={overallCounts.answeredReviewCount}
									unansweredReviewCount={overallCounts.unansweredReviewCount}
									notVisitedCount={
										overallCounts.notVisitedCount -
										(currentQuestion && currentQuestion.total_seconds === 0 ? 1 : 0)
									}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default GenericV2ExamScreen;
