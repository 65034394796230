import React, { useState, useEffect, useRef } from "react";
import DetailsHeader from "./components/DetailsHeader";
import JeemainHeader from "../../components/JeemainHeader";
import "./styles.css";
import Swal from "sweetalert2";
import LoadingComp from "./components/LoadingComp";
import ApiUrl from "../../common/ApiUrl";
import Api, { handleAPIError } from "../../common/Api";
import ExamSummaryReview from "./components/ExamSummaryReview";
import QuestionBox from "./components/QuestionBox";
import AttemptSummary from "./components/AttemptSummary";
import QuestionNumbers from "./components/QuestionNumbers";
import useMediaQuery from "../../utils/hooks/use-media-query";
import { segmentEvents } from "../../utils/constants";

const ExamScreen = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const [quesStatusList, setQuesStatusList] = useState([]);
	const [submitStatus, setSubmitStatus] = useState(false);
	const [quesStatusCount, setQuesStatusCount] = useState({
		notVisited: 0,
		notAnswered: 0,
		answered: 0,
		markedForReview: 0,
		answeredAndMarkedReview: 0,
	});
	const [timeLeft, setTimeLeft] = useState(null);
	const [examOver, setExamOver] = useState(false);
	const [questionBank, setQuestionBank] = useState({});
	const [currentQuestion, setCurrentQuestion] = useState({});
	const [startTime, setStartTime] = useState(new Date());
	const totalQuestions = useRef(0);
	const isMobile = useMediaQuery("(max-width: 991px)");
	const mainRef = useRef(null);
	const boxRef = useRef(null);

	useEffect(() => {
		if (timeLeft !== null && timeLeft <= 0 && !examOver) onTimeEnd();
	}, [timeLeft, examOver]);

	useEffect(() => {
		if (!isLoading) {
			if (isMobile) {
				mainRef.current?.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else
				boxRef.current?.scrollTo({
					top: 0,
					behavior: "smooth",
				});
		}
	}, [currentQuestion.id, isLoading]);

	useEffect(() => {
		window.addEventListener("popstate", (e) => {
			props.history.go(1);
		});
		document.addEventListener("contextmenu", (e) => e.preventDefault());
	}, [props.history]);

	useEffect(() => {
		const examStart = async () => {
			let formdata = new FormData();
			formdata.append("student_id", props.location.state.studentId);

			Api.doUpload("POST", formdata, ApiUrl.START_EXAM + `${props.location.state.examdId}/`)
				.then((response) => {
					if (response?.status) {
							const { data } = response;
							setTimeLeft(data.num_seconds);
							updateColorCount(data.question_status_list);
							totalQuestions.current = data.question_status_list.length;
							addToQuestionBank(data.section_questions, data.section_questions[0].question_bank_assoc.id);
							setIsLoading(false);
					} else {
						handleAPIError(response.message);
						props.history.push({
							pathname: "/student/objectivetest",
						});
					}
				})
				.catch((err) => {
					console.log(err);
					alertUser();
					props.history.push({
						pathname: "/student/objectivetest",
					});
				});
		};

		examStart();
	}, []);

	const addToQuestionBank = (list, selectQuestionId) => {
		if (questionBank[list[0]?.question_bank_assoc?.id]) return;
		const tempQuesBank = { ...questionBank };
		list.forEach((ques) => {
			tempQuesBank[ques.question_bank_assoc.id] = ques;
		});
		setQuestionBank(tempQuesBank);
		if (selectQuestionId) {
			setCurrentQuestion(tempQuesBank[selectQuestionId]);
			setStartTime(new Date());
		}
	};

	const alertUser = (message = "Something went wrong") => {
		Swal.fire({
			title: message,
			showDenyButton: false,
			showCancelButton: false,
			confirmButtonText: "Ok",
		});
	};

  const onChangeQuestion = async (questionStatus, save, review) => {
    Api.trackEvent(segmentEvents.MAINS.QUESTION_CLICK);
    const quesId = questionStatus.question_id;

		const savedDB = await updateQuestionChanges(currentQuestion, save, review);

		if (!savedDB) {
			setCurrentQuestion((question) => ({
				...question,
				ans_given: "",
				is_option1_selected: false,
				is_option2_selected: false,
				is_option3_selected: false,
				is_option4_selected: false,
			}));

			return;
		}

		if (questionBank[quesId]) {
			//if selected question is present in the question bank
			setCurrentQuestion(questionBank[quesId]);
			setStartTime(new Date());
		} else {
			try {
				setIsLoading(true);
				const response = await Api.doFetch(
					"GET",
					{},
					ApiUrl.FETCH_QUESTIONS +
						`${props.location.state.examdId}/?student_id=${props.location.state.studentId}&subject_id=${questionStatus.subject_id}&section_id=${questionStatus.section_id}`
				);
				if (response) {
					if (response.status === true) {
						setTimeLeft(response.data.num_seconds);
						updateColorCount(response.data.question_status_list);
						addToQuestionBank(response.data.section_questions, questionStatus.question_id);
					} else {
						handleAPIError(response.message);
					}
				}
				setIsLoading(false);
			} catch (err) {
				setIsLoading(false);
				alertUser(`1${err.message}`);
			}
		}
	};

  const getPrevQuestion = () => {
    Api.trackEvent(segmentEvents.MAINS.BACK_CLICK);
    if (currentQuestion.serial_no === "1") return;
    const prevQuestion =
      quesStatusList[parseInt(currentQuestion.serial_no) - 2];
    onChangeQuestion(prevQuestion);
  };

  const getNextQuestion = (save, review) => {
    Api.trackEvent(segmentEvents.MAINS.NEXT_CLICK);
    const nextQuestion =
      quesStatusList[
        parseInt(
          currentQuestion.serial_no === totalQuestions.current
            ? currentQuestion.serial_no - 1 // if last question then pass the same question's status
            : currentQuestion.serial_no
        )
      ];
    onChangeQuestion(nextQuestion, save, review);
  };

	const updateColorCount = (quesList) => {
		const tempCount = {
			notVisited: 0,
			notAnswered: 0,
			answered: 0,
			markedForReview: 0,
			answeredAndMarkedReview: 0,
		};
		quesList.forEach((ql) => {
			if (ql.color_id === "0") {
				tempCount.notVisited += 1;
			}
			if (ql.color_id === "1") {
				tempCount.answered += 1;
			}
			if (ql.color_id === "2") {
				tempCount.markedForReview += 1;
			}
			if (ql.color_id === "3") {
				tempCount.answeredAndMarkedReview += 1;
			}
			if (ql.color_id === "4") {
				tempCount.notAnswered += 1;
			}
		});
		setQuesStatusList(quesList);
		setQuesStatusCount(tempCount);
	};

	const getTimeTaken = () => {
		const currentTime = new Date();
		const timeTaken = Math.floor((currentTime - startTime) / 1000);
		return timeTaken === 0 ? 1 : timeTaken + currentQuestion.total_seconds;
	};

	const updateQuestionChanges = async (question, save, review = false) => {
		const timeTaken = getTimeTaken();
		const updatedQuestion = {
			...question,
			total_seconds: timeTaken,
		};

		const formdata = new FormData();

		formdata.append("student_id", props.location.state.studentId);
		formdata.append("ques_id", question.id);
		formdata.append("total_seconds", timeTaken === 0 ? 1 : timeTaken);

		if (review && !save) {
			updatedQuestion.ans_given = "";
			updatedQuestion.is_option1_selected = false;
			updatedQuestion.is_option2_selected = false;
			updatedQuestion.is_option3_selected = false;
			updatedQuestion.is_option4_selected = false;
		}

		if (review || save) {
			if (question.question_type_id === "2") {
				[1, 2, 3, 4].forEach((i) => {
					const key = `is_option${i}_selected`;
					formdata.append(key, updatedQuestion[key]);
				});
			} else {
				formdata.append("ans_given", updatedQuestion.ans_given);
			}
		}

		formdata.append("review_status", review);

		try {
			const response = await Api.doUpload("POST", formdata, ApiUrl.SAVE_EXAM + `${props.location.state.examdId}/`);

			if (response) {
				if (response.status) {
					setTimeLeft(response.data.num_seconds);
					setQuesStatusList(response.data.question_status_list);
					updateColorCount(response.data.question_status_list);
					setIsLoading(false);

					setQuestionBank((quesBank) => {
						quesBank[question.question_bank_assoc.id] = updatedQuestion;
						return quesBank;
					});

					return true;
				} else {
					handleAPIError(response.message);

					return false;
				}
			}
		} catch (err) {
			alertUser(`${err.message}`);

			return false;
		}
	};

	const onEndExam = () => {
		let formdata = new FormData();
		formdata.append("student_id", props.location.state.studentId);
		Api.doUpload("POST", formdata, ApiUrl.SUBMIT_EXAM + `${props.location.state.examdId}/`)
			.then((response) => {
				if (response) {
					if (response.status === true) {
						props.history.push({
							pathname: "/student/SubmitSuccessfully",
						});
					} else {
						handleAPIError(response.message);
					}
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				alertUser(`${err.message}`);
			});
	};

  const resetQuestion = () => {
    Api.trackEvent(segmentEvents.MAINS.CLEAR_CLICK);
    let formdata = new FormData();
    const questionId = currentQuestion.question_bank_assoc.id;
    formdata.append("student_id", props.location.state.studentId);
    formdata.append("ques_id", currentQuestion.id);

		const tempQues = {
			...currentQuestion,
			ans_given: "",
			is_option1_selected: false,
			is_option2_selected: false,
			is_option3_selected: false,
			is_option4_selected: false,
		};
		setCurrentQuestion(tempQues);
		const tempQuesBank = { ...questionBank };
		tempQuesBank[questionId] = tempQues;
		setQuestionBank(tempQuesBank);
		Api.doUpload("POST", formdata, ApiUrl.RESET_QUESTION + `${props.location.state.examdId}/`)
			.then((response) => {
				if (response) {
					if (response.status === true) {
						setTimeLeft(response.data.num_seconds);
						setQuesStatusList(response.data.question_status_list);
						updateColorCount(response.data.question_status_list);
					} else {
						handleAPIError(response.message);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				alertUser(`${err.message}`);
			});
	};

	const onlyNumericInput = (value) => {
		const re = /^-?[0-9]*\.?[0-9]*$/;
		if (re.test(value)) {
			const tempCurrQues = { ...currentQuestion };
			tempCurrQues.ans_given = value;
			setCurrentQuestion(tempCurrQues);
		}
	};

	const optionhandler = (value) => {
		const tempCurrQues = { ...currentQuestion };
		tempCurrQues.ans_given = value;
		setCurrentQuestion(tempCurrQues);
	};

	const multipleOptionHandler = (key) => {
		const tempCurrQues = { ...currentQuestion };
		tempCurrQues[key] = !!!tempCurrQues[key];
		setCurrentQuestion(tempCurrQues);
	};

	const isQuestionAnswered = () => {
		if (currentQuestion.ans_given === "" && currentQuestion.question_type_id !== "2") {
			alertUser(currentQuestion.question_type_id === "1" ? "Please choose an option" : "Please enter the answer");
			return false;
		} else if (currentQuestion.question_type_id === "2" && !isAnyOptionChecked()) {
			alertUser("Please choose an option");
			return false;
		}
		return true;
	};

  const saveAndNext = () => {
    Api.trackEvent(segmentEvents.MAINS.SAVENEXT_CLICK);
    if (isQuestionAnswered()) getNextQuestion(true, false);
  };

  const saveAndMarkForReview = () => {
    Api.trackEvent(segmentEvents.MAINS.SAVEREVIEW_CLICK);
    if (isQuestionAnswered()) getNextQuestion(true, true);
  };

	const isAnyOptionChecked = () => {
		let answered = false;
		[1, 2, 3, 4].forEach((i) => {
			if (currentQuestion[`is_option${i}_selected`]) {
				answered = true;
			}
		});
		return answered;
	};

  const markForeReviewAndNext = () => {
    Api.trackEvent(segmentEvents.MAINS.REVIEWNEXT_CLICK);
    getNextQuestion(false, true);
  };

	const openToggleCallback = (open) => {
		if (open === false) {
			document.getElementById("one_question_data_con").className = "col-md-12 screen-col";
		}
		if (open === true) {
			document.getElementById("one_question_data_con").className = "col-md-8 screen-col";
		}
	};

	const changeSubmitStatus = (status) => {
		setSubmitStatus(status);
	};

	const onTimeEnd = () => {
		setExamOver(true);
	};

	if (isLoading) {
		return <LoadingComp />;
	}

	return (
		<div
			className="mains-main-container"
			style={{
				justifyContent: submitStatus || examOver ? "flex-start" : "space-between",
			}}
			ref={mainRef}>
			<JeemainHeader />
			<DetailsHeader
				timeLeft={timeLeft}
				theme={props.location.state.exam_theme_assoc_id}
				exam_name={props.location.state.exam_name}
				student_name={props.location.state.studentName}
				onTimeEnd={onTimeEnd}
			/>
			{submitStatus || examOver ? (
				<ExamSummaryReview
					quesStatusList={quesStatusList}
					quesStatusCount={quesStatusCount}
					currentQuestion={currentQuestion}
					onEndExam={onEndExam}
					changeSubmitStatus={changeSubmitStatus}
				/>
			) : (
				<div className="container content">
					<div className="row">
						<div className="col-md-8 screen-col" id="one_question_data_con">
							<QuestionBox
								optionhandler={optionhandler}
								multipleOptionHandler={multipleOptionHandler}
								onlyNumericInput={onlyNumericInput}
								saveAndNext={saveAndNext}
								resetQuestion={resetQuestion}
								saveAndMarkForReview={saveAndMarkForReview}
								markForeReviewAndNext={markForeReviewAndNext}
								getNextQuestion={getNextQuestion}
								getPrevQuestion={getPrevQuestion}
								changeSubmitStatus={changeSubmitStatus}
								boxRef={boxRef}
								openToggleCallback={openToggleCallback}
								question={currentQuestion}
								totalQuestions={totalQuestions.current}
							/>
						</div>
						<div
							className="col-md-4 screen-col right-col"
							id="right_question_con"
							style={{ justifyContent: "flex-start" }}>
							<AttemptSummary currentQuestion={currentQuestion} quesStatusCount={quesStatusCount} />
							<QuestionNumbers
								quesStatusList={quesStatusList}
								currentQuestion={currentQuestion}
								onChangeQuestion={onChangeQuestion}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ExamScreen;
