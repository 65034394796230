import React from "react";
import parse from "html-react-parser";
import "./styles.css";
import Arrow from "../../icons/arrow.png";
import QuestionButtons from "../QuestionButtons";
import Api from "../../../../common/Api";
import { segmentEvents } from "../../../../utils/constants";

const QuestionBox = (props) => {
  const [open, setOpen] = React.useState(false);
  const { question } = props;

  const onOpenClose = () => {
    props.openToggleCallback && props.openToggleCallback(open);
    setOpen(!open);
  };

  return (
    <>
      <div id="quess-con" ref={props.boxRef}>
        <div className="question-con" style={{ width: "100%" }}>
          <div>
            <p className="question_number_para">
              Question {question.serial_no} :
            </p>
            <hr />
            <div className="ques-img-container">
              {parse(question.question_bank_assoc.question)}
            </div>
            {question.question_type_id === "1" ? (
              <SingleChoice {...props} />
            ) : question.question_type_id === "2" ? (
              <MultipleChoice {...props} />
            ) : question.question_type_id === "3" ? (
              <SingleInput {...props} />
            ) : null}
          </div>
        </div>
        <div
          onClick={() => {
            onOpenClose();
            if (open) {
              Api.trackEvent(segmentEvents.MAINS.SIDEBAR_COLLAPSE);
            }
            else {
              Api.trackEvent(segmentEvents.MAINS.SIDEBAR_EXPAND);
            }
          }}
          className="arrow_btn"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "30px",
          }}
        >
          <img
            src={Arrow}
            style={{
              transform: open ? `rotate(180deg)` : `rotate(0deg)`,
            }}
            alt="arrow"
          />
        </div>
      </div>
      <QuestionButtons {...props} />
    </>
  );
};

export default QuestionBox;

const SingleInput = (props) => {
  return (
    <div>
      <input
        type="text"
        value={props.question.ans_given}
        onChange={(e) => props.onlyNumericInput(e.target.value)}
      />
    </div>
  );
};

const MultipleChoice = (props) => {
  return (
    <>
      <p className="option_para">Options:</p>
      <hr></hr>
      <br />
      {[1, 2, 3, 4].map((i) => (
        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "start",
            flexDirection: "row",
            display: "flex",
            margin: "1em 0",
          }}
          key={i}
        >
          {`${i})`} &nbsp;{" "}
          {parse(props.question.question_bank_assoc[`option${i}`] ?? "")}
        </div>
      ))}
      <br />
      <div className="checkbox-con">
        {[1, 2, 3, 4].map((i) => (
          <div key={i}>
            <input
              name="checkbox_btn"
              value={`${i}`}
              checked={props.question[`is_option${i}_selected`]}
              onChange={(e) =>
                props.multipleOptionHandler(`is_option${i}_selected`)
              }
              type="checkbox"
            ></input>{" "}
            &nbsp;
            <label>{`${i})`}</label>
          </div>
        ))}
      </div>
    </>
  );
};

const SingleChoice = (props) => {
  return (
    <>
      <p className="option_para">Options:</p>
      <hr></hr>
      <br />
      {[1, 2, 3, 4].map((i) => (
        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "start",
            flexDirection: "row",
            display: "flex",
            margin: "1em 0",
          }}
          key={i}
        >
          {`${i})`} &nbsp;{" "}
          {parse(props.question.question_bank_assoc[`option${i}`] ?? "")}
        </div>
      ))}
      <br />
      <div className="radio-con">
        {[1, 2, 3, 4].map((i) => (
          <div key={i}>
            <input
              name="radio_btn"
              value={`${i}`}
              checked={props.question.ans_given === i.toString()}
              onChange={(e) => props.optionhandler(e.target.value)}
              type="radio"
            ></input>{" "}
            &nbsp;
            <label>{`${i})`}</label>
          </div>
        ))}
      </div>
    </>
  );
};
