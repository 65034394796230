import React, { useState } from "react";
import "./index.css";
import DashboardNavbar from "./navbar";
import Attendance from "../../components/common/Attendance";
import Mpp from "./mpp";
import Test from "./test";
import Classes from "./classes";
import AITSBanner from "./components/aits-banner";
import OnlineCoursePopup from "./onlineCoursePopup";
import SuccessPopup from "./successPopup";
import ErrorPopup from "./errorPopup";
import { useTimer } from "../../Student/genericV2/common/use-timer";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../utils/hooks/use-media-query";
import { ToastContainer } from "react-toastify";
import { resetCornerState, startDashboardLoader } from "../../store/actions";
import Api from "../../common/Api";
import { segmentEvents, dashboardIds } from "../../utils/constants";
import NCERTNEW from "./ncert";
import JEECorner from "./jee";
import NEETCorner from "./neet";
import { requestACall } from "../../utils/helperFunctions";
import Layout from "../../components/layout";

const isScrolledIntoView = (el, offset = -100) => {
	const top = el.getBoundingClientRect().top;
	return top + offset >= 0 && top - offset <= window.innerHeight;
};

const IDs = Object.values(dashboardIds).reverse();

const Dashboard = () => {
	const {
		attendance,
		banner,
		classes,
		dpps,
		exams,
		loadingAttendance,
		loadingExams,
		loadingDpps,
		loadingClasses,
		loadingBanner,
		attendanceDates,
		loadingAttendanceDates,
		holidayList
	} = useSelector((state) => state.dashboard);
	const dispatch = useDispatch();
	let data = localStorage.getItem("userdetail");
	data = data && JSON.parse(data);
	const token = data?.token;
	const { student_data: studentData, student_subjects: studentSubjects } = useSelector(
		(state) => state.StudentDetail?.StudentDetail ?? {}
	);
	const { time, start: startTimer } = useTimer({ timerType: "DECREMENTAL" });
	const [onlineModalOpen, setOnlineModalOpen] = useState(false);
	const [activeSection, setActiveSection] = useState(null);
	const debounce = React.useRef(false);
	const stopScrollListner = React.useRef(false);
	const loading = loadingAttendance || loadingDpps || loadingExams || loadingClasses || loadingBanner || !studentData;
	const isMobile = useMediaQuery("(max-width: 991px)");

	const onlineModalOpenHandler = () => {
		setOnlineModalOpen(true);
		Api.trackEvent(segmentEvents.FREEDASHBOARD.HEADER_ENROLL);
		Api.moEngageTrackEvent("Web - Free user Enroll clicked", {
			Timestamp: Date(),
			"course name": banner.course_name,
		});
	};

	const onlineModalCloseHandler = () => {
		setOnlineModalOpen(false);
	};

	const [successModalOpen, setSuccessModalOpen] = useState(false);
	const [errorModalOpen, setErrorModalOpen] = useState(false);

	const successModalOpenHandler = () => {
		setSuccessModalOpen(true);
		setTimeout(() => {
			window.location.reload();
			dispatch(startDashboardLoader());
			setSuccessModalOpen(false);
		}, 5000);
		startTimer(5);
	};

	const errorModalOpenHandler = () => {
		setErrorModalOpen(true);
		setTimeout(() => {
			setErrorModalOpen(false);
		}, 3500);
	};

	React.useEffect(() => {
		if (loading || isMobile) return;
		const scrollListner = () => {
			if (stopScrollListner.current) return;
			if (!debounce.current) {
				debounce.current = true;
				setTimeout(() => {
					IDs.forEach((id) => {
						const el = document.getElementById(id);

						if (el) {
							if (isScrolledIntoView(el)) {
								setActiveSection(id);
							}
						}
					});
					debounce.current = false;
				}, 50);
			}
		};
		window.addEventListener("scroll", scrollListner);
		return () => {
			window.removeEventListener("scroll", scrollListner);
		};
	}, [loading, isMobile]);

	React.useEffect(() => {
		if (activeSection) return;
		if (!studentData) return;
		if (studentData.is_free) {
			setActiveSection(dashboardIds.NCERT);
		} else {
			setActiveSection(dashboardIds.MPP);
		}
	}, [studentData]);

	React.useEffect(() => {
		dispatch(resetCornerState());
	}, []);

	return (
		<Layout>
			{!loading && (
				<div className="dashboard_container">
					{banner.show_banner && !banner.post_enrollment && (
						<OnlineCoursePopup
							open={onlineModalOpen}
							handleClose={onlineModalCloseHandler}
							successModalOpenHandler={successModalOpenHandler}
							errorModalOpenHandler={errorModalOpenHandler}
							banner={banner}
							studentData={studentData}
						/>
					)}

					<SuccessPopup open={successModalOpen} time={time} />
					<ErrorPopup open={errorModalOpen} />

					{banner.show_banner && <AITSBanner banner={banner} onlineModalOpenHandler={onlineModalOpenHandler} />}

					<div className="dashboard_layout">
						<div className="dashboard_nav">
							<DashboardNavbar
								stopScrollListner={stopScrollListner}
								activeSection={activeSection}
								setActiveSection={setActiveSection}
								isFree={studentData.is_free}
							/>
						</div>
						{studentData.is_free ? (
							<div className="dashboard_content">
								<div
									style={{
										marginTop: "-4rem",
									}}>
									<NCERTNEW active={activeSection === dashboardIds.NCERT} />
								</div>
								<JEECorner active={activeSection === dashboardIds.JEE} />
								<NEETCorner active={activeSection === dashboardIds.NEET} />
								<div
									style={{
										marginTop: "4rem",
									}}>
									<Mpp
										active={activeSection === dashboardIds.MPP}
										dpps={dpps}
										studentData={studentData}
										token={token}
										isFree
										requestACall={requestACall}
									/>
								</div>
								<Test
									active={activeSection === dashboardIds.TESTS}
									exams={exams}
									studentData={studentData}
									token={token}
									isFree
									studentSubjects={studentSubjects}
									requestACall={requestACall}
								/>
								<Classes
									classes={classes}
									active={activeSection === dashboardIds.CLASSES}
									isFree
									requestACall={requestACall}
								/>
							</div>
						) : (
							<div className="dashboard_content">
								<Mpp
									active={activeSection === dashboardIds.MPP}
									dpps={dpps}
									studentData={studentData}
									token={token}
								/>
								<Test
									active={activeSection === dashboardIds.TESTS}
									exams={exams}
									studentData={studentData}
									token={token}
									studentSubjects={studentSubjects}
								/>
								<Classes classes={classes} active={activeSection === dashboardIds.CLASSES} />
								<NCERTNEW active={activeSection === dashboardIds.NCERT} />
								<JEECorner active={activeSection === dashboardIds.JEE} />
								<NEETCorner active={activeSection === dashboardIds.NEET} />
							</div>
						)}

						<div className="dashboard_attendance" style={{ ...(studentData?.is_free && { overflow: "hidden" }) }}>
							<Attendance
								isFree={studentData.is_free}
								attendance={attendance}
								studentSubjects={studentSubjects}
								requestACall={requestACall}
								attendanceDates={attendanceDates}
								holidayList={holidayList}
							/>
						</div>
					</div>
				</div>
			)}
			<ToastContainer />
		</Layout>
	);
};

export default Dashboard;
