import React, { useMemo } from "react";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import "../styles/exam-screen.css";
import { getOfflineCurrentExamData } from "../../../store/examStore";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";

function GenericV2InstructionsModal({ show, setShow, isOffline }) {
    const handleOpen = (e) => {
        Api.trackEvent(segmentEvents.GENERIC.INSTR_CLICK);
        e.preventDefault();
        setShow(true);
    };

    const handleClose = (e) => {
        if (e && e.preventDefault) e.preventDefault();
        setShow(false);
    };

    const location = useLocation();

    const instructions = useMemo(() => {
        if (isOffline) {
            const examInfo = getOfflineCurrentExamData()

            if (examInfo?.examData?.exam_instructions) {
                return parse(examInfo.examData.exam_instructions);
            }
        } else if (location.state?.examData?.data?.exam_instructions) {
            return parse(location.state.examData.data.exam_instructions);
        }

        return "";
    }, [isOffline, location.state]);

    return (
        <>
            <a href="#generic-instructions-modal" onClick={handleOpen}>
                <i className="fas fa-info-circle"></i>
            </a>

            <Modal width={1000} title="Instructions" visible={show} footer={null} onCancel={handleClose}>
                <div className="container-modal">
                    <div>{instructions}</div>

                    <div className="text-left">
                        <span className="general-inst-heading">General Instructions</span> <br />
                        <span>
                            The Questions Palette displayed on the right side of screen will show the status of each question using one of the following
                            symbols:
                        </span>
                        <table>
                            <tbody>
                                <tr className="table-row">
                                    <td className="table-data">
                                        <div className="not-visited-div"> </div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-heading">Not Visited</div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-inst-content">Question has not been visited yet.</div>
                                    </td>
                                </tr>

                                <tr className="table-row">
                                    <td className="table-data">
                                        <div className="not-answered-active-div"> </div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-heading">Not Answered</div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-inst-content">Question has been visited but not answered.</div>
                                    </td>
                                </tr>

                                <tr className="table-row">
                                    <td className="table-data">
                                        <div className="answered-active-div"> </div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-heading">Answered</div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-inst-content">
                                            Question has been answered and will be considered for evaluation.
                                        </div>
                                    </td>
                                </tr>

                                <tr className="table-row">
                                    <td className="table-data">
                                        <div className="not-ans-mkd-active-div"> </div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-heading">Not Answered & Marked For Review</div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-inst-content">Question has not been answered and is marked for review.</div>
                                    </td>
                                </tr>

                                <tr className="table-row">
                                    <td className="table-data">
                                        <div className="ans-mkd-active-div"> </div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-heading">Answered & Marked For Review</div>
                                    </td>
                                    <td className="table-data">
                                        <div className="question-pallete-inst-content">
                                            Question has been answered and is marked for review. Answer will be considered for evaluation.
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="text-center" style={{ marginTop: 10 }}>
                        <a href="#generic-instructions-close" type="button" className="close-modal-btn" onClick={handleClose}>
                            Close
                        </a>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default GenericV2InstructionsModal;
