import React, { useEffect } from "react";
import { Detector } from "react-detect-offline"
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { examPersistor, examStore } from "../../../store/examStore";
import GenericV2OfflineInstructions from "./instructions";
import GenericV2Exam from "../index";
import { cancelOfflineSync, startOfflineSync, trySync } from "./queue";

export default function GenericV2Offline() {

    useEffect(() => {
        startOfflineSync()

        return () => {
            cancelOfflineSync();
        }
    }, []);

    return (
        <Provider store={examStore}>
            <PersistGate loading={null} persistor={examPersistor}>
				<Detector
					onChange={(online) => {
						if (online) trySync();
					}}
					render={() => null}
				/>

                <Switch>
                    <Route
                        path="/student/generic/:examId/instructions"
                        component={GenericV2OfflineInstructions}
                        exact
                    />

                    <Route
                        path="/student/generic/:examId/exam"
                        component={() => <GenericV2Exam isOffline />}
                        exact
                    />
                    
                    <Route path="*" component={() => <Redirect to="/student" />} />
                </Switch>
            </PersistGate>
        </Provider>
    );
}
